module.exports = {
    cdc: {
        oidcApiKey: '4_LpgU77oFB9zTqzDMpak6LA',
        cdnDomain: 'idp.login-test.messefrankfurt.com',
        oidcConsentEndpoint: 'https://api.login-test.messefrankfurt.com/services/clp/consent'
    },
    userCentrics: {
        src: 'https://app.usercentrics.eu/browser-ui/latest/bundle.js',
        id: 'wrBYx7VXF'
    },
    app: {
        defaultReturnUrl: 'https://messefrankfurt.com'
    },
    dataWidget: {
        src: 'https://datawidget-test.messefrankfurt.com/datawidget.js'
    },
    oauth2: {
        getTokenEndpoint: 'https://api.login-test.messefrankfurt.com/services/clp/get-token'
    },
    mapp: {
        src: 'https://www.messefrankfurt.com/etc/designs/messefrankfurt/vendor/webtrekk_v3_2.js',
        trackId: '739752143485978',
        userCentricsRef: 'Mapp Intelligence Full Feature Tracking'
    }
}
